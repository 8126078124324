import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Output,
} from '@angular/core';

import { ButtonComponent } from '../core/button/button.component';

@Component({
  standalone: true,
  selector: 'do-preview-box',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent],
  styleUrl: `./preview-box.component.scss`,
  template: `
    <div class="d-flex p-4 rounded preview-box-container">
      <div class="flex-grow-1 me-3  overflow-hidden">
        <ng-content></ng-content>
      </div>
      <div>
        <do-button
          [style.visibility]="showEdit() ? 'visible' : 'hidden'"
          cssClasses="btn-primary"
          [icon]="'edit'"
          (clicked)="edit.emit()"
        ></do-button>
      </div>
    </div>
  `,
})
export class PreviewBoxComponent {
  @Output()
  edit = new EventEmitter<void>();

  showEdit = input(true);
}
