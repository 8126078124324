import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BreadcrumbItem } from '@do/app-common';

@Component({
  templateUrl: `./breadcrumb.component.html`,
  styleUrls: [`./breadcrumb.component.scss`],
  selector: 'do-breadcrumb',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class BreadcrumbComponent {
  @Input()
  breadcrumbItems: BreadcrumbItem[] = [];

  @Output()
  navigate = new EventEmitter<number>();
}
