<nav *ngIf="breadcrumbItems.length > 0">
  <ol class="breadcrumb">
    <ng-container *ngFor="let item of breadcrumbItems; let i = index">
      <li
        *ngIf="i !== breadcrumbItems.length - 1 && !item.disable"
        class="breadcrumb-item"
      >
        <button class="breadcrumb-link" (click)="navigate.emit(i)">
          {{ item.text }}
        </button>
      </li>
      <li
        *ngIf="i === breadcrumbItems.length - 1 || item.disable"
        class="breadcrumb-item active"
        aria-current="page"
      >
        {{ item.text }}
      </li>
    </ng-container>
  </ol>
</nav>
