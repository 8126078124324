import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Output,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '../core/button/button.component';

@Component({
  standalone: true,
  selector: 'do-form-box',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, ReactiveFormsModule, ButtonComponent, TranslateModule],
  styleUrl: `./form-box.component.scss`,
  template: `
    <form class="form-container" (submit)="onSubmit()">
      <div class="content">
        <ng-content></ng-content>
      </div>
      <div class="action-button-container px-3 pb-3">
        <do-button
          *ngIf="showCancel()"
          type="button"
          [cssClasses]="'btn-light'"
          [icon]="'undo'"
          [text]="'Cancel' | translate"
          (clicked)="cancel.emit()"
        ></do-button>
        <do-button
          type="submit"
          cssClasses="btn-primary"
          [icon]="'done'"
          [disabled]="!enableSave()"
          [text]="'Save' | translate"
        ></do-button>
      </div>
    </form>
  `,
})
export class FormBoxComponent {
  @Output()
  save = new EventEmitter<void>();

  @Output()
  cancel = new EventEmitter<void>();

  form = input<FormGroup>();
  showCancel = input(false);
  enableSave = input(false);

  onSubmit() {
    this.save.emit();
    return false;
  }
}
